import Banner1 from "../assets/batitu-mx.png";
//import Banner2 from "../assets/Banner-Batitu-2.jpg";
//import Banner1 from "../assets/Banner-Batitu-Cyberday1.jpg";
//import Banner2 from "../assets/Banner-Batitu-Cyberday2.jpg";
//import BolsaReciclaje from "../assets/banner2.png";

const descript = [
  // {
  //   name: 'Banner1 de 4 Bandejas',
  //   info: `¡Adiós residuos, hola compost! \n
  //   Lo mejor que podrás hacer para cambiarte a un estilo de vida más sostenible es dominar el arte del compost. En un mes ya podrás ver como se va reduciendo tu basura y el aporte que estas haciendo al planeta. Para hacerlo solo necesitas ganas de hacerlo, esta Vermi, lombrices y los residuos orgánicos que quieras convertir en hermoso fertilizante. ¿Empezamos?`,
  //   price: "69.990",
  //   link: "https://denda.cl/collections/destacados-2/products/Banner1-de--bandejas-de-plastico-reciclado",
  //   image: Banner1
  // },
  {
    image: Banner1
  }
]

export default descript