import React from "react"
import Base from "../layout/Base"


const Terminos = () => {

    return (
        <Base>
              <div id='collection-component-1632342425721' style={{display: "none"}}></div>
            <div className="text-page-title"><p><b>TERMINOS Y CONDICIONES</b></p></div>
            <div class="text-page-content">
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Bienvenidos a Batitu, la primera tienda online enfocada en ofrecer productos y servicios orientados a mejorar la calidad de vida de las personas, disminuyendo el impacto en el medio ambiente, disponible a trav&eacute;s de nuestro sitio web www.Batitu.cl, en adelante el &ldquo;Sitio&rdquo;. Por favor, lee con atenci&oacute;n los t&eacute;rminos y condiciones en adelante &ldquo;T&eacute;rminos y Condiciones&rdquo; y nuestra pol&iacute;tica de privacidad, en adelante la &ldquo;Pol&iacute;tica de Privacidad&rdquo;, ambas disponibles en nuestro sitio.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>El Sitio es operado por la sociedad Factory Comercia SpA, RUT N&ordm; 76.580.606-2, domiciliada en Cardenal Belarmino 1058, comuna de Vitacura, Santiago, y su representante legal, para estos efectos, es Felipe Parragu&eacute; Guzm&aacute;n.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Estos t&eacute;rminos permiten que accedas y uses el sitio y los servicios, adem&aacute;s de su contenido, y constituyen un contrato con Factory Comercio SpA, en adelante tambi&eacute;n &ldquo;Batitu&rdquo; o la &ldquo;Empresa&rdquo;, para todos los efectos legales. Los T&eacute;rminos y Condiciones contenidos en este instrumento se aplicar&aacute;n y se entender&aacute;n como parte integrante de todos los actos y contratos que se ejecuten o celebren mediante los sistemas de oferta y comercializaci&oacute;n comprendidos en este sitio entre el usuario, en adelante el &ldquo;Usuario&rdquo; o los &ldquo;Usuarios&rdquo; de este sitio, y Batitu, y por cualquiera de las otras sociedades o empresas que sean filiales o vinculadas a ella, y que hagan uso del Sitio, a las cuales se las denominar&aacute; en adelante tambi&eacute;n en forma indistinta como las &ldquo;Empresas&rdquo;, o bien la &ldquo;Empresa Oferente&rdquo;, el &ldquo;Proveedor&rdquo; o la &ldquo;Empresa Proveedora&rdquo;, seg&uacute;n convenga al sentido del texto.</span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}><br /></span><strong>1. Aspectos Generales</strong></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>El acceso, uso y ventas de los servicios ofrecidos por Batitu, dentro del Sitio www.Batitu.cl se rigen por los T&eacute;rminos y Condiciones descritos a continuaci&oacute;n, as&iacute; como por la legislaci&oacute;n que sea aplicable de la Rep&uacute;blica de Chile. En consecuencia, todas las visitas, contratos y transacciones que se realicen en este sitio, como asimismo sus efectos jur&iacute;dicos, quedar&aacute;n regidos por estas disposiciones y la legislaci&oacute;n pertinente. CUALQUIER PERSONA QUE NO ACEPTE ESTOS T&Eacute;RMINOS Y CONDICIONES GENERALES Y LAS POL&Iacute;TICAS DE PRIVACIDAD, LOS CUALES TIENEN UN CAR&Aacute;CTER OBLIGATORIO Y VINCULANTE, DEBER&Aacute; ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS SERVICIOS.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Se entender&aacute;n conocidos y aceptados estos T&eacute;rminos y Condiciones por el s&oacute;lo hecho de ingresar y hacer uso de nuestro Sitio por el Usuario, lo que implicar&aacute; la aceptaci&oacute;n plena de las condiciones establecidas en este documento y en las Pol&iacute;ticas de Privacidad de Batitu. Por la utilizaci&oacute;n del sitio y/o sus servicios, el Usuario se obligar&aacute; a cumplir expresamente con las mismas, no pudiendo alegar el desconocimiento de los T&eacute;rminos y Condiciones Generales y de la Pol&iacute;tica de Privacidad.</span></p>
                <p>&nbsp;</p>
                <ol start="2">
                <li><strong> Registro y uso del Sitio</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Batitu informar&aacute;, de manera f&aacute;cil y accesible, los pasos que el Usuario debe seguir para adquirir los productos ofrecidos por medio del Sitio, y le informar&aacute; v&iacute;a correo electr&oacute;nico una vez que sea recibida la solicitud de compra del producto. Esta solicitud pasar&aacute; por un proceso de validaci&oacute;n de los datos del Usuario, de recolecci&oacute;n de los productos del pedido en base al stock disponible, validaci&oacute;n del pago por parte del Usuario y, finalmente, se cerrar&aacute; la venta emiti&eacute;ndose el documento correspondiente, el cual ser&aacute; enviado al Usuario por correo electr&oacute;nico una vez que se haya enviado su pedido. El s&oacute;lo hecho que el Usuario siga los pasos que para tales efectos se indican en este sitio para efectuar una compra, equivale a aceptar que la Empresa ha dado cumplimiento efectivo a las condiciones contenidas en esta cl&aacute;usula. Indicar&aacute;, adem&aacute;s, su direcci&oacute;n de correo postal o electr&oacute;nico y los medios t&eacute;cnicos a disposici&oacute;n del Usuario para identificar y corregir errores en el env&iacute;o o en sus datos personales.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>La Empresa se reserva el derecho de restringir el acceso al Sitio, la Empresa podr&aacute; cancelar, eliminar o bloquear la cuenta de cualquier Usuario en el Sitio sin justificaci&oacute;n ni previo aviso, y ello no dar&aacute; al Usuario derecho a reclamar ninguna indemnizaci&oacute;n. La confidencialidad de los datos de la cuenta y contrase&ntilde;a del Usuario son de su exclusiva responsabilidad.</span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}><br /></span><strong>3. Derechos de los Usuarios</strong></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Los Usuarios gozar&aacute;n de todos los derechos que le reconoce la legislaci&oacute;n sobre protecci&oacute;n al consumidor vigente en el territorio de Chile, y adem&aacute;s los que se le otorgan en estos t&eacute;rminos y condiciones. La sola visita de este sitio en el cual se ofrecen determinados bienes, no impone a los Usuarios obligaci&oacute;n alguna, a menos que haya aceptado en forma inequ&iacute;voca las condiciones ofrecidas por la Empresa, en la forma indicada en estos T&eacute;rminos y Condiciones.</span></p>
                <p>&nbsp;</p>
                <ol start="4">
                <li><strong> Responsabilidad del Usuario</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>La confidencialidad de los datos de la cuenta y contrase&ntilde;a del Usuario as&iacute; como todas las actividades realizadas en el Sitio bajo su nombre de Usuario y contrase&ntilde;a son de su exclusiva responsabilidad.</span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}>El acceso y uso del Sitio, como el uso de la informaci&oacute;n y contenidos incluidos en el mismo, ser&aacute;n de exclusiva responsabilidad del Usuario.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>El Usuario es el &uacute;nico responsable por cualquier informaci&oacute;n, dato, texto, fotograf&iacute;a, video, m&uacute;sica, sonido, software o cualquier otro tipo de obra o expresi&oacute;n que publique o transmita en el Sitio.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>El Usuario deber&aacute; cumplir con toda la normativa aplicable, local, nacional e internacional, y es el &uacute;nico responsable por todos los actos y omisiones que sucedan en relaci&oacute;n con su cuenta, registro o contrase&ntilde;a, y con relaci&oacute;n a la informaci&oacute;n o material que se incluya en el Sitio en uso de su cuenta.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Los Usuarios deber&aacute;n cumplir con todas las obligaciones dispuestas en estos T&eacute;rminos y Condiciones.</span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}><br /></span><strong>5. Autorizaci&oacute;n de Uso a La Empresa</strong></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Toda la informaci&oacute;n de cualquier clase y que en cualquier forma el Usuario incorpore, transmita o env&iacute;e al Sitio se considerar&aacute; de car&aacute;cter p&uacute;blico, y que contiene una licencia irrevocable a favor de la Empresa para su reproducci&oacute;n, distribuci&oacute;n, exhibici&oacute;n, transmisi&oacute;n, retransmisi&oacute;n, comercializaci&oacute;n, o cualquier otra modificaci&oacute;n por todo el tiempo de protecci&oacute;n que el derecho de autor tenga previsto legalmente. Asimismo, se entender&aacute; que dicha autorizaci&oacute;n se hace a t&iacute;tulo gratuito y que el Usuario renuncia expresamente cualquier pretensi&oacute;n remuneratoria por parte de la Empresa al momento de incorporar, transmitir o enviar dicha informaci&oacute;n al Sitio.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Siguiendo lo enunciado anteriormente, la Empresa tendr&aacute; el derecho a modificar, alterar o editar cualquier informaci&oacute;n o archivo que el Usuario haya incorporado al Sitio, sin que se pueda entender que en absoluto existe alg&uacute;n tipo de lesi&oacute;n a los derechos morales de autor, que el Usuario pueda ostentar sobre ellas.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Sin perjuicio de la reproducci&oacute;n temporal de los contenidos referidos en el p&aacute;rrafo anterior s&oacute;lo para efectos del correcto acceso y utilizaci&oacute;n del Sitio de acuerdo a estos T&eacute;rminos y Condiciones, queda expresamente prohibida y son consideradas un delito seg&uacute;n los t&eacute;rminos de la Ley 17.336 de Propiedad Intelectual, cuando corresponda, cualquier forma de reproducci&oacute;n, distribuci&oacute;n, exhibici&oacute;n, transmisi&oacute;n, retransmisi&oacute;n, emisi&oacute;n en cualquier forma, almacenamiento en cualquier forma, digitalizaci&oacute;n, puesta a disposici&oacute;n, traducci&oacute;n, adaptaci&oacute;n, arreglo comunicaci&oacute;n p&uacute;blica o cualquier otro tipo acto por el cual el Usuario pueda servirse comercial o no comercialmente, directa o indirectamente, en su totalidad o parcialmente de cualquiera de los contenidos de las obras sujetas a los derechos mencionados anteriormente. El incumplimiento de lo se&ntilde;alado dar&aacute; lugar a la aplicaci&oacute;n de las sanciones penales y civiles que correspondan.</span></p>
                <p>&nbsp;</p>
                <ol start="6">
                <li><strong> Protecci&oacute;n de Datos Personales</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Los datos personales que entreguen los Usuarios s&oacute;lo podr&aacute;n ser utilizados por la Empresa para perfeccionar contratos de venta, recibir pagos y mejorar la labor de informaci&oacute;n y comercializaci&oacute;n de los productos y servicios con los Usuarios, y no podr&aacute;n ser entregados a terceros no relacionados con la Empresa. El Usuario registrado podr&aacute; ejercer sus derechos de informaci&oacute;n, modificaci&oacute;n, eliminaci&oacute;n, cancelaci&oacute;n y/o bloqueo de sus datos personales cuando lo estime pertinente, seg&uacute;n lo establecido en la Ley N&ordm; 19.628 sobre Protecci&oacute;n de la Vida Privada.</span></p>
                <p>&nbsp;</p>
                <ol start="7">
                <li><strong> Pol&iacute;ticas de Privacidad</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Conoce las &ldquo;Pol&iacute;ticas de Privacidad&rdquo; disponibles en nuestra p&aacute;gina web para obtener informaci&oacute;n y saber c&oacute;mo se utiliza tu informaci&oacute;n personal en Batitu.</span></p>
                <p>&nbsp;</p>
                <ol start="8">
                <li><strong> Comunicaciones Electr&oacute;nicas</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Toda comunicaci&oacute;n promocional o publicitaria que le enviemos electr&oacute;nicamente a los Usuarios tendr&aacute;n nuestra identidad como remitente y en el asunto se detallar&aacute; a qu&eacute; se refiere. Adem&aacute;s, tendr&aacute;n un link para que el destinatario, si lo desea, solicite la suspensi&oacute;n de esos env&iacute;os. De esta forma cesar&aacute;n los mensajes a todos los Usuarios que lo hayan solicitado.</span></p>
                <p>&nbsp;</p>
                <ol start="9">
                <li><strong>Alcance de los Precios Informados en el Sitio</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Batitu no modificar&aacute; las condiciones bajo las cuales haya contratado con los Usuarios en el Sitio. Mientras aparezcan en el Sitio, los precios informados estar&aacute;n a disposici&oacute;n de los Usuarios.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Cualquier cambio en las informaciones publicadas en el Sitio, incluyendo las referidas a mercader&iacute;as, servicios, precios, existencias y condiciones, promociones y ofertas, tendr&aacute; lugar antes de recibir una orden de compra y solo se referir&aacute; a operaciones futuras, sin afectar, en caso alguno, derechos adquiridos por los Usuarios.</span></p>
                <p>&nbsp;</p>
                <ol start="10">
                <li><strong>Garant&iacute;a y Condiciones de Anulaci&oacute;n</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Los productos que ofrece Batitu en su Sitio tienen garant&iacute;a y es muy importante que leas atentamente c&oacute;mo funciona para utilizarla sin problema:</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Garant&iacute;a por falla del producto: Si recibes un producto que presenta alguna falla, ofrecemos un tiempo de garant&iacute;a que est&aacute; detallada en la ficha de descripci&oacute;n de cada producto, esto sin perjuicio de la garant&iacute;a establecida por ley (3 meses). A mayor abundamiento, la garant&iacute;a legal establece que el Usuario podr&aacute; optar entre la reparaci&oacute;n gratuita del bien o, previa restituci&oacute;n, su reposici&oacute;n o la devoluci&oacute;n de la cantidad pagada, dentro de los tres meses siguientes a la fecha en que se haya recibido el producto.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Garant&iacute;a por da&ntilde;o: Si recibes un producto que presente alg&uacute;n da&ntilde;o, cont&aacute;ctanos inmediatamente, es decir, en un plazo no mayor a 24 hrs. Si excedes este plazo no se har&aacute; efectiva la garant&iacute;a del producto, ya que se asumir&aacute; que el producto se da&ntilde;&oacute; por un mal uso.</span></p>
                <p><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}>Garant&iacute;a por insatisfacci&oacute;n: En caso de que recibas el producto y no cumpla con tus expectativas, te ofrecemos la opci&oacute;n de devoluci&oacute;n en dinero, siempre y cuando nos avises dentro de los primeros 10 d&iacute;as luego de recibida tu compra.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Consideraciones generales:</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>De acuerdo a la facultad que confiere el art&iacute;culo 3BIS de la Ley del Consumidor, Batitu no otorga derecho a retracto en las siguientes categor&iacute;as:</span></p>
                <p><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}>&ldquo;Salud y Belleza&rdquo;</span></p>
                <p><span style={{fontWeight: "400;"}}>&ldquo;Alimentaci&oacute;n&rdquo;</span></p>
                <p><span style={{fontWeight: "400;"}}>&ldquo;Infantil&rdquo; (pa&ntilde;ales y toallitas)</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Para hacer uso de la garant&iacute;a de un producto y anular tu compra, debes contactarnos inmediatamente para indicar c&oacute;mo ayudarte, ya sea en forma telef&oacute;nica, por correo o a trav&eacute;s de nuestras RRSS.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Si necesitamos que nos env&iacute;es el producto, &eacute;ste debe venir sin uso, con sus sellos y embalajes originales, en perfectas condiciones y con los accesorios en caso de tenerlos.</span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}>En caso de tener alguna falla de fabricaci&oacute;n, nosotros enviaremos el producto al servicio t&eacute;cnico para que sea reparado.</span></p>
                <p>&nbsp;</p>
                <ol start="11">
                <li><strong> Devoluci&oacute;n de Productos y Dinero</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Puedes solicitar un cambio o devoluci&oacute;n en los siguientes casos:</span></p>
                <p>&nbsp;</p>
                <ol>
                <li style={{fontWeight: "400;"}}><span style={{fontWeight: "400;"}}>Si recibiste un producto distinto al que pediste.</span></li>
                <li style={{fontWeight: "400;"}}><span style={{fontWeight: "400;"}}>Si el producto tiene alguna falla.</span></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Para solicitar un cambio o devoluci&oacute;n de producto tienes 30 d&iacute;as desde su recepci&oacute;n para poder realizarlo o 10 d&iacute;as en caso de insatisfacci&oacute;n, siempre y cuando:</span></p>
                <p>&nbsp;</p>
                <ol>
                <li style={{fontWeight: "400;"}}><span style={{fontWeight: "400;"}}>Los productos no hayan sido usados.</span></li>
                <li style={{fontWeight: "400;"}}><span style={{fontWeight: "400;"}}>Debe estar en el empaque original y en buen estado.</span></li>
                <li style={{fontWeight: "400;"}}><span style={{fontWeight: "400;"}}>Si son productos electr&oacute;nicos (con pilas o enchufe), &eacute;stos deben estar sellados (sellos originales).</span></li>
                <li style={{fontWeight: "400;"}}><span style={{fontWeight: "400;"}}>En el caso de productos fr&aacute;giles (que contienen alg&uacute;n material sujeto a quiebre), &eacute;stos deben estar en su envase original con todas las protecciones correspondientes.</span></li>
                <li style={{fontWeight: "400;"}}><span style={{fontWeight: "400;"}}>La reposici&oacute;n de los productos o la devoluci&oacute;n del dinero, se realiza con posterioridad a la recepci&oacute;n y validaci&oacute;n de del servicio t&eacute;cnico en caso de que aplique falla de f&aacute;brica.&nbsp;</span></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>En relaci&oacute;n a la devoluci&oacute;n por insatisfacci&oacute;n o falla, la empresa puede ofrecer las siguientes modalidades de compensaci&oacute;n:</span></p>
                <p>&nbsp;</p>
                <ol>
                <li style={{fontWeight: "400;"}}><span style={{fontWeight: "400;"}}>Devoluci&oacute;n en dinero: realizando el dep&oacute;sito en el medio de pago que haya utilizado en un per&iacute;odo no superior a 15 d&iacute;as h&aacute;biles luego de que el cliente facilite sus datos bancarios, cuesti&oacute;n que ser&aacute; informada a trav&eacute;s del correo electr&oacute;nico que se hubiere registrado.</span></li>
                <li style={{fontWeight: "400;"}}><span style={{fontWeight: "400;"}}>Cr&eacute;ditos Batitu: ofrecemos cr&eacute;ditos por el monto de la compra para que sean utilizados en un futuro pedido en nuestro sitio web.</span></li>
                </ol>
                <p>&nbsp;</p>
                <ol start="12">
                <li><strong> Medios de Pago</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Los productos y servicios ofrecidos en Batitu, salvo que se se&ntilde;ale una forma diferente para casos particulares u ofertas de determinados bienes o servicios, s&oacute;lo pueden ser pagados con los medios que en cada caso espec&iacute;ficamente se indiquen.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Cuando se compra un producto a trav&eacute;s de Batitu.cl se podr&aacute; realizar el pago a trav&eacute;s del proveedor de servicios seleccionado, pudiendo este ser Webpay, Paypal , Kiphu o una transferencia bancaria directa, donde se realiza una transferencia bancaria a nuestra cuenta. Puedes pagar con los diferentes m&eacute;todos de pago especificados.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Cada vez que recibamos un pago, &eacute;ste ser&aacute; confirmado v&iacute;a correo electr&oacute;nico al Usuario que realiz&oacute; el desembolso de dinero para confirmar la recepci&oacute;n de &eacute;ste e iniciar el proceso de confirmaci&oacute;n y despacho de productos.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Los descuentos utilizados por los Usuarios no ser&aacute;n reintegrados en caso de devoluci&oacute;n de los productos, por cualquier causa que esto ocurriera.</span></p>
                <p>&nbsp;</p>
                <ol start="13">
                <li><strong> Despacho de los productos</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Los productos adquiridos a trav&eacute;s del sitio se sujetar&aacute;n a las condiciones de despacho y entrega elegidas por el Usuario y disponibles en el sitio. La informaci&oacute;n del lugar de env&iacute;o es de exclusiva responsabilidad del Usuario.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Los tiempos de env&iacute;o dependen de la regi&oacute;n en la que vives:</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Si vives en la Regi&oacute;n Metropolitana, las entregas las realizamos de lunes a viernes entre las 9:00 y las 18:00 hrs, dentro de 2 a 5 d&iacute;as h&aacute;biles.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Si vives en regiones, las entregas se realizan de lunes a viernes entre las 9:00 y las 18:00 hrs, y el tiempo depender&aacute; del lugar d&oacute;nde est&eacute;s (regiones extremas: entre 7 y 12 d&iacute;as h&aacute;biles).</span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}>En caso de recibir tu pedido incompleto o un producto defectuoso, debes enviarnos una fotograf&iacute;a a info@Batitu.cl para solucionar la situaci&oacute;n.</span></p>
                <p>&nbsp;</p>
                <ol start="14">
                <li><strong> Responsabilidad de Batitu</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Batitu har&aacute; lo posible dentro de sus capacidades para que la transmisi&oacute;n del Sitio sea ininterrumpida y libre de errores. Sin embargo, dada la naturaleza de la Internet, dichas condiciones no pueden ser garantizadas. En el mismo sentido, el acceso del Usuario a la cuenta puede ser ocasionalmente restringido o suspendido con el objeto de efectuar reparaciones, mantenimiento o introducir nuevos servicios.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>En las ventas efectuadas en este Sitio, Batitu le enviar&aacute; una confirmación por escrito de la misma al correo electr&oacute;nico informado por el Usuario. Asimismo, Batitu informar&aacute; su direcci&oacute;n de correo electr&oacute;nico y los medios t&eacute;cnicos a disposici&oacute;n del Usuario para identificar y corregir errores en el env&iacute;o o en sus datos; y, si el documento electr&oacute;nico en que se formalice el contrato fuera archivado, c&oacute;mo ser&aacute; accesible al consumidor. Estas obligaciones se entender&aacute;n cumplidas por el hecho de seguir el Usuario los pasos que se indican en el Sitio para hacer una compra determinada.</span></p>
                <p>&nbsp;</p>
                <ol start="15">
                <li><strong> Limitaci&oacute;n de Responsabilidad</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Batitu no garantiza ning&uacute;n contenido de este Sitio, ni ning&uacute;n contenido de cualquier otro sitio al que se remita o al que se acceda por enlace de hipertexto a trav&eacute;s de este Sitio.</span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}>Batitu no ser&aacute; responsable por la p&eacute;rdida que pudiera surgir por basarse en informaci&oacute;n o materiales publicados en este Sitio.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Determinadas partes de este Sitio y sitios web relacionados tienen enlaces a sitios web de internet externos, y otros sitios web de internet externos pueden contener enlaces a este Sitio. Batitu no es responsable del contenido de ning&uacute;n Sitio de internet externo.</span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}>No podemos garantizar que nuestro Sitio y cualquier documento, archivo e informaci&oacute;n albergados o descargados de este Sitio no contendr&aacute;n virus, troyanos, gusanos, bombas de tiempo, registradores de pulsaciones de teclas, spyware, adware y otros programas perjudiciales o c&oacute;digo de computaci&oacute;n dise&ntilde;ado para afectar negativamente el funcionamiento de cualquier software o hardware de computaci&oacute;n; No somos responsables de ninguna p&eacute;rdida que pueda sufrir una persona, que surja de dicho c&oacute;digo perjudicial o c&oacute;digo de computaci&oacute;n.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Los enlaces a otros sitios web se proporcionan s&oacute;lo con fines informativos. No tenemos control sobre el contenido de los sitios web a los que se incluyen enlaces, y no seremos responsables por el material que se encuentra en estos ni por p&eacute;rdidas o da&ntilde;os que puedan surgir del uso de estos.</span></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>En la medida que la ley lo permita, Batitu no ser&aacute; responsable por los da&ntilde;os directos, indirectos, especiales, consecuenciales, incidentales, punitivos (incluyendo lucro cesante y da&ntilde;o moral), incluso, entre otros, los que resulten de la p&eacute;rdida de ganancias, ingresos o ahorros, p&eacute;rdida o da&ntilde;o de los datos o interrupci&oacute;n del negocio a causa del uso o la incapacidad de uso del Sitio o de cualquier material, informaci&oacute;n, producto o servicio al que se acceda por medio de este Sitio. A&uacute;n en caso de haber sido advertido sobre la posibilidad de ocurrencia de tales da&ntilde;os.</span></p>
                <p>&nbsp;</p>
                <ol start="16">
                <li><strong> Indemnidad</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>El Usuario se obliga a indemnizar y mantener indemne y libre de da&ntilde;os a La Empresa, sus subsidiarias, controladores, y/o empresas vinculadas de y contra toda y cualquier acci&oacute;n o juicio de responsabilidad, reclamo, denuncia, penalidad, intereses, costos, gastos, multas, honorarios, iniciado por terceros debido a o con origen en cualquiera de sus acciones en el Sitio.</span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}><br /></span><strong>17. Propiedad Intelectual e Industrial</strong></p>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Los textos, im&aacute;genes, logos, signos distintivos, sonidos, animaciones, videos, c&oacute;digos fuente y resto de contenidos incluidos en este sitio son propiedad de la Empresa, o &eacute;sta dispone, en su caso, del derecho de reproducci&oacute;n de los mismos y, en tal sentido, constituyen bienes protegidos por la legislaci&oacute;n de propiedad intelectual e industrial vigente y aplicable.</span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}>Sin perjuicio de la reproducci&oacute;n temporal de los contenidos referidos en el n&uacute;mero anterior s&oacute;lo para efectos del correcto acceso y utilizaci&oacute;n del Sitio de acuerdo a estos T&eacute;rminos y Condiciones, queda expresamente prohibida y son consideradas un delito seg&uacute;n los t&eacute;rminos de la Ley 17.336 de Propiedad Intelectual, cuando corresponda, cualquier forma de reproducci&oacute;n, distribuci&oacute;n, exhibici&oacute;n, transmisi&oacute;n, retransmisi&oacute;n, emisi&oacute;n en cualquier forma, almacenamiento en cualquier forma, digitalizaci&oacute;n, puesta a disposici&oacute;n, traducci&oacute;n, adaptaci&oacute;n, arreglo comunicaci&oacute;n p&uacute;blica o cualquier otro tipo acto por el cual el Usuario pueda servirse comercial o no comercialmente, directa o indirectamente, en su totalidad o parcialmente de cualquiera de los contenidos de las obras sujetas a los derechos descriptos anteriormente. El incumplimiento de lo se&ntilde;alado dar&aacute; lugar a la aplicaci&oacute;n de las sanciones penales y civiles que correspondan.</span></p>
                <p>&nbsp;</p>
                <ol start="18">
                <li><strong> Reclamos y/o Controversias</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>En caso un Usuario considere que sus derechos personales, morales, intelectuales o cualquier otro est&aacute;n siendo violados de cualquier forma en el Sitio, se le agradecer&aacute; enviar un e-mail a la siguiente direcci&oacute;n de correo electr&oacute;nico: [contacto@Batitu.cl, con sus datos personales, una descripci&oacute;n de la situaci&oacute;n que afirma viola sus derechos y los detalles que nos permitan ubicar la situaci&oacute;n que el Usuario afirma viola sus derechos dentro del Sitio. Una vez recibida la comunicaci&oacute;n del Usuario, la Empresa analizar&aacute; la procedencia de su reclamo y, de corresponder a su s&oacute;lo criterio, proceder&aacute; de acuerdo a lo solicitado.</span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}><br /></span><span style={{fontWeight: "400;"}}>Toda controversia surgida entre el Usuario y la Empresa en relaci&oacute;n con la interpretaci&oacute;n y cumplimiento de los presentes T&eacute;rminos y Condiciones y a las compras y ventas que dichas partes celebren en virtud de aqu&eacute;llos, ser&aacute; sometida y resuelta por los Tribunales Ordinarios de Justicia de Santiago, a cuya jurisdicci&oacute;n y competencia el Usuario y la Empresa se someten expresamente. Asimismo, para todos los efectos legales que fueren procedentes, estos t&eacute;rminos y condiciones se regir&aacute;n por las leyes de la Rep&uacute;blica de Chile que le fueren aplicables en cada caso determinado.</span></p>
                <p>&nbsp;</p>
                <ol start="19">
                <li><strong> Edad M&iacute;nima de Contrataci&oacute;n</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>No vendemos productos a menores de edad. Los productos para ni&ntilde;os presentes en nuestro cat&aacute;logo web deben ser comprados por adultos. Los menores de 18 a&ntilde;os s&oacute;lo podr&aacute;n utilizar los servicios de Batitu bajo la supervisi&oacute;n de un padre o un tutor.</span><span style={{fontWeight: "400;"}}><br /><br /></span></p>
                <ol start="20">
                <li><strong> Segurida de la Informaci&oacute;n</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Sin perjuicio de lo se&ntilde;alsdo en nuestrra Pol&iacute;tica de Privacidad, Batitu y el Sitio cuentan con un Certificado de Seguridad SSL que permite asegurar la autenticidad del Sitio, como el cifrado de toda la informaci&oacute;n que entregan los Usuarios. Cada vez que el Usuario se registra en Batitu y entrega informaci&oacute;n de car&aacute;cter personal, sin importar el lugar geogr&aacute;fico en donde se encuentre, a efectos de comprar un producto, el navegador por el cual ejecuta la compra se conecta al sitio a trav&eacute;s del protocolo SSL que acredita que el Usuario se encuentra efectivamente en el Sitio de Batitu y en sus servidores (esto puede comprobarse porque aparece el c&oacute;digo HTTPS en la barra de direcciones del navegador). De esta forma se establece un m&eacute;todo de cifrado de la informaci&oacute;n entregada por los Usuario y una clave de sesi&oacute;n &uacute;nica. Esta tecnolog&iacute;a permite que la informaci&oacute;n de car&aacute;cter personal de los Usuarios, como sus nombres, direcciones y datos de tarjetas bancarias, sean codificadas antes, para que no pueda ser le&iacute;da cuando viaja a trav&eacute;s de internet. Todos los certificados SSL se crean para un servidor particular, en un dominio espec&iacute;fico y para una entidad comercial comprobada.</span></p>
                <p>&nbsp;</p>
                <ol start="21">
                <li><strong>Cambios a los T&eacute;rminos y Condiciones</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style={{fontWeight: "400;"}}>Batitu podr&aacute; modificar los T&eacute;rminos y Condiciones en cualquier momento, haciendo p&uacute;blicos en el sitio los t&eacute;rminos modificados. Todos los t&eacute;rminos modificados entrar&aacute;n en vigencia cumplidos 10 (diez) d&iacute;as de su publicaci&oacute;n. Dentro de los 5 (cinco) d&iacute;as siguientes a la publicaci&oacute;n de las modificaciones introducidas, el Usuario deber&aacute; comunicar por e-mail haciendo clic aqu&iacute; si no acepta las mismas; en ese caso quedar&aacute; disuelto el v&iacute;nculo contractual y ser&aacute; inhabilitado como miembro. Vencido este plazo, se considerar&aacute; que el Usuario acepta los nuevos t&eacute;rminos y el contrato continuar&aacute; vinculando a ambas partes.</span></p>
                <p>&nbsp;</p>
            </div>
        </Base>
    )
}
export default Terminos;
