import React from "react"
import Base from "../layout/Base"

const Despacho = () => {
    return (
        <Base>
              <div id='collection-component-1632323254850' style={{display: "none"}}></div>
            <div className="text-page-title"><p><b>Despachos</b></p></div>
            <div class="text-page-content">
                <p>&nbsp;</p>
                <p><b>¿PUEDO ENVIAR MI PRODUCTO A OTRA DIRECCIÓN?</b></p>
<p>Sí, al momento de comprar puedes elegir una dirección de despacho, que puede ser distinta a la dirección de tu cuenta.</p>
<p><b>¿QUÉ HAGO SI NO ESTABA AL MOMENTO DE LA ENTREGA?</b></p>
<p>En todos los pedidos que realices, te daremos el número de guía que nos proporciona el Operador Logístico. Si no estabas cuando llegamos a dejar el producto al domicilio que nos indicaste, no te preocupes que nosotros te ayudaremos a rastrearlo.</p>
<p><b>¿CUÁLES SON LOS PLAZOS DE LOS ENVÍOS?</b></p>
<p>Los productos adquiridos a través del sitio se sujetarán a las condiciones de envío y entrega elegidas por el Usuario y disponibles en el sitio. La información del lugar de envío es de exclusiva responsabilidad del Usuario.</p>
<p>Nuestros plazos de entrega comienzan a correr una vez que realizas tu compra y tu compra llegará de 2 a 5 días hábiles a partir del día de compra.</p>
<p><b>¿PUEDO RETIRAR MI COMPRA/PRODUCTO EN ALGÚN LUGAR?</b></p>
<p>Déjanos esa tarea a nosotros, te lo entregaremos al domicilio que desees.</p>
<p><b>¿BATITU HACE ENVÍOS FUERA DE MÉXICO?</b></p>
<p>Por el momento sólo despachamos dentro de la República Mexicana y también estamos en Chile www.batitu.com.mx, estamos trabajando para que pronto exista Batitu Latam</p>
<p><b>¿BATITU REALIZA ENVÍOS A TODO MÉXICO?</b></p>
<p>Sí, en Batitu queremos que todas las personas puedan adquirir nuestros productos y por ello enviamos lo que necesites donde sea que te encuentres.</p>
<p><b>¿QUÉ HAGO SI MI PEDIDO NO HA LLEGADO?</b></p>
<p>Si tu pedido no ha llegado dentro del plazo establecido, contáctanos a través de nuestro sitio web o a través de Facebook. Ojalá nunca tengas que leer esta pregunta, ya que trabajamos con el corazon para que esto no ocurra</p>
<p><b>¿CÓMO PUEDO SABER EL ESTADO DE MI PEDIDO?</b></p>
<p>Para conocer el estado de tu pedido, debes haber iniciado tu sesión en la sección Mi Cuenta y buscar tus compras en la sección “Mis pedidos”, donde estará actualizada la información de tu envío.</p>
                <p>&nbsp;</p>
                
            </div>
        </Base>
    )
}
export default Despacho;
