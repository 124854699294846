import React from "react"
import Base from "../layout/Base"

const Preguntas = () => {
    return (
        <Base>
              <div id='collection-component-1632323254850' style={{display: "none"}}></div>
            <div className="text-page-title"><p><b>Preguntas Frecuentes</b></p></div>
            <div class="text-page-content">
                <p><strong>CAMBIOS Y DEVOLUCIONES</strong></p>
                <p>&nbsp;</p>
                <p><b>En BATITU te ofrecemos dos tipos de devoluciones:</b></p>
<p><b>Por Defecto de Fabricación:</b> Si tu producto presenta alguna falla de origen, dentro de los 3 primeros meses luego de recibido, será derivado al servicio técnico. BATITU coordinará el envío al mismo. En caso de tener alguna falla de fabricación, nosotros enviaremos el producto a servicio técnico para que sea reparado.</p>
<p><b>Por Daño:</b> Si recibes un producto que presente algún daño, contáctanos inmediatamente, es decir, en un plazo no mayor a 72hrs. Si excedes este plazo no se hará efectiva la garantía del producto, ya que se asumirá que el producto se dañó por un mal uso.</p>
<p><b>Consideraciones Generales para Casos de Devolución:</b> Para solicitar un cambio, el producto debe estar en perfectas condiciones; es decir, con todos los embalajes originales, etiquetas y manual de uso. Las devoluciones se harán en la forma en la que se realizó el pago o en Créditos BATITU. Las transferencias bancarias se hacen efectivas en un tiempo máximo de 10 días hábiles.</p>
<p><b>IMPORTANTE:</b></p>
<p>Hay categorías que NO pueden ser devueltas como Alimentos, Productos Infantiles, Productos de Uso Cosmético, Productos de Uso Íntimo o Higiénico, Productos de Cuidado y Aseo Personal.</p>
                <p>&nbsp;</p>
                <p><strong>COMPRAS</strong></p>
                <p>&nbsp;</p>
                <p><b>¿PUEDO COMPRAR UN PRODUCTO PARA REGALO?</b></p>
<p>Contamos con todos los métodos de pago necesarios para que tengas la mejor experiencia de compra</p>
<br/>
<p><b>Tarjetas de Crédito</b></p>
<table>
<tbody>
<tr height="52px">
<td width="229px">
<div></div>
<ul>
<li>Visa</li>
</ul>
</td>
<td width="229px">
<div><meta charset="utf-8"/></div>
<ul>
<li>Master Card</li>
</ul>
</td>
</tr>
</tbody>
</table>
<table>
<tbody>
<tr height="52px">
<td width="229px">
<div><meta charset="utf-8"/></div>
<ul>
<li>​A través de PayPal</li>
</ul>
</td>
<td width="229px">
<div><meta charset="utf-8"/></div>
<ul>
<li>American Express</li>
</ul>
</td>
</tr>
</tbody>
</table>
<p><strong><br/>Tarjetas de Débito</strong></p>
<table height="61">
<tbody>
<tr height="52px">
<td width="229px">
<ul>
<li>Visa</li>
</ul>
</td>
<td width="229px">
<div><meta charset="utf-8"/></div>
<ul>
<li>Master Card</li>
</ul>
</td>
</tr>
</tbody>
</table>
<p><b><br/>Pagos con Efectivo</b></p>
<p>OXXO</p>
<br/>
<p><b>Meses Sin Intereses*</b></p>
<p>*Dependiendo de tu banco</p>
<br/>
<p><b>Transferencia SPEI</b></p>
<p> </p>
<p><b>¿TIENEN TIENDA FÍSICA?</b></p>
<p>BATITU funciona como una tienda 100% online, en nuestro sitio puedes conocer todos nuestros productos y servicios a través de nuestro sitio web. Trabajamos cada día para que tu experiencia de compra sea mil veces mejor que al comprar en una tienda física.</p>
<br/>
<p><b>¿QUÉ HAGO SI PAGUÉ Y NO RECIBÍ EL CORREO DE CONFIRMACIÓN?</b></p>
<p>Si pagaste y no recibiste el correo de confirmación de tu compra, puedes escribirnos y te responderemos lo antes posible. También puedes probar volviendo a actualizar tu correo o revisando tu carpeta de spam, para asegurarnos que nuestro correo no cayó ahí.</p>
<br/>
<p><b>¿QUÉ INFORMACIÓN PEDIMOS EN BATITU PARA COMPRAR?</b></p>
<p>Cuando realices una compra, sólo te pediremos que ingreses la información necesaria para el pago y el envío de tu compra. Esto incluye tus datos personales, dirección de envío, correo electrónico y los temas relacionados con el despacho. Es importante que sepas que nunca conservaremos la información de tu tarjeta de crédito.</p>
<br/>
<p><b>¿ES SEGURO COMPRAR EN BATITU?</b></p>
<p>Nuestro sitio cumple con los más altos estándares de seguridad, al comprar en BATITU todos tus datos están protegidos. Para que compres tranquilo, en nuestro sitio adoptamos las siguientes medidas de seguridad:</p>
<p>1. Certificado de Seguridad SSL que nos permite asegurar la autenticidad del sitio, como el cifrado de toda la información que nos entregan nuestros clientes. Cada vez que el usuario se registra en BATITU y nos entrega información de carácter personal, sin importar el lugar geográfico en donde se encuentre, a efectos de comprar un producto, el navegador por el cual ejecuta la compra se conecta al sitio a través del protocolo SSL que acredita que nuestro cliente se encuentra efectivamente en BATITU y en nuestros servidores (esto puedes verlo porque aparece el código HTTPS en la barra de direcciones del navegador). De esta forma se establece un método de cifrado de la información entregada por nuestros clientes y una clave de sesión única. Esta tecnología permite que la información de carácter personal de nuestros clientes, como sus nombres, direcciones y datos de tarjetas bancarias, sean codificadas antes, para que no pueda ser leída cuando viaja a través de internet. Todos los certificados SSL se crean para un servidor particular, en un dominio específico y para una entidad comercial comprobada.</p>
<p>2. Certificado de Site Blindado, quiere decir que el sitio está permanentemente sujeto a evaluaciones de posibles vulnerabilidades (en tiempo real).</p>
                <p>&nbsp;</p>
                <p><strong>ENTREGA DE PRODUCTOS</strong></p>
                <p>&nbsp;</p>
                <p><b>¿PUEDO ENVIAR MI PRODUCTO A OTRA DIRECCIÓN?</b></p>
<p>Sí, al momento de comprar puedes elegir una dirección de despacho, que puede ser distinta a la dirección de tu cuenta.</p>
<p><b>¿QUÉ HAGO SI NO ESTABA AL MOMENTO DE LA ENTREGA?</b></p>
<p>En todos los pedidos que realices, te daremos el número de guía que nos proporciona el Operador Logístico. Si no estabas cuando llegamos a dejar el producto al domicilio que nos indicaste, no te preocupes que nosotros te ayudaremos a rastrearlo.</p>
<p><b>¿CUÁLES SON LOS PLAZOS DE LOS ENVÍOS?</b></p>
<p>Los productos adquiridos a través del sitio se sujetarán a las condiciones de envío y entrega elegidas por el Usuario y disponibles en el sitio. La información del lugar de envío es de exclusiva responsabilidad del Usuario.</p>
<p>Nuestros plazos de entrega comienzan a correr una vez que realizas tu compra y tu compra llegará de 2 a 5 días hábiles a partir del día de compra.</p>
<p><b>¿PUEDO RETIRAR MI COMPRA/PRODUCTO EN ALGÚN LUGAR?</b></p>
<p>Déjanos esa tarea a nosotros, te lo entregaremos al domicilio que desees.</p>
<p><b>¿BATITU HACE ENVÍOS FUERA DE MÉXICO?</b></p>
<p>Por el momento sólo despachamos dentro de la República Mexicana y también estamos en Mexico www.batitu.com.mx, estamos trabajando para que pronto exista BATITU Latam</p>
<p><b>¿BATITU REALIZA ENVÍOS A TODO MÉXICO?</b></p>
<p>Sí, en BATITU queremos que todas las personas puedan adquirir nuestros productos y por ello enviamos lo que necesites donde sea que te encuentres.</p>
<p><b>¿QUÉ HAGO SI MI PEDIDO NO HA LLEGADO?</b></p>
<p>Si tu pedido no ha llegado dentro del plazo establecido, contáctanos a través de nuestro sitio web o a través de Facebook. Ojalá nunca tengas que leer esta pregunta, ya que trabajamos con el corazon para que esto no ocurra</p>
<p><b>¿CÓMO PUEDO SABER EL ESTADO DE MI PEDIDO?</b></p>
<p>Para conocer el estado de tu pedido, debes haber iniciado tu sesión en la sección Mi Cuenta y buscar tus compras en la sección “Mis pedidos”, donde estará actualizada la información de tu envío.</p>
                <p>&nbsp;</p>
                <p><strong>GARANTÍA</strong></p>
                <p>&nbsp;</p>
                <p><b>GARANTÍA</b></p>
<p>Todos los productos BATITU tienen una garantía de 3 meses si el objeto se encuentra defectuoso,además de ofrecerte reparación en el servicio técnico correspondiente si tu compra presenta fallas de fábrica, pero no si el desperfecto se origina por una mala manipulación una vez recibido el producto.</p>

                <p>&nbsp;</p>
                <p><strong>REGISTRO</strong></p>
                <p>&nbsp;</p>
                <p><b>¿CÓMO PUEDO MODIFICAR MIS DATOS?</b></p>
<p>Para editar, agregar o modificar alguno de tus datos registrados, debes ir a Mi cuenta y luego puedes modificar tus datos personales, dirección de envío, entre otros.</p>
<p><b>¿CÓMO PUEDO CAMBIAR MI CONTRASEÑA?</b></p>
<p>Para cambiar tu contraseña debes ir a Mi cuenta en la parte superior a la derecha del sitio, luego dar clic en “Detalles de la Cuenta” y “Cambiar contraseña”. Ahí, podrás crear una nueva contraseña para ingresar a tu cuenta.</p>
<p><b>OLVIDÉ MI CONTRASEÑA, ¿QUÉ HAGO?</b></p>
<p>Antes de correr en círculos, ve a "Olvidé mi contraseña", que está ubicado en la página de inicio del sitio. Deberás ingresar el correo electrónico con el que te registraste en www.batitu.com.mx y te enviaremos un correo electrónico con las instrucciones para que puedas ingresar con una nueva contraseña.</p>
<p><b>¿CÓMO ME REGISTRO EN BATITU?</b></p>
<p>Para obtener una cuenta en BATITU, ve a la página de inicio, selecciona la opción "Ingresar" en la parte superior derecha, agrega tu nombre y correo electrónico y crea una contraseña de acceso. También tenemos la opción de que te registres a través de tu cuenta de FB. En BATITU tenemos una política de privacidad, por lo que ninguno de tus datos personales será entregado ni divulgado a terceros bajo ninguna circunstancia</p>
                <p>&nbsp;</p>
            </div>
        </Base>
    )
}
export default Preguntas;
