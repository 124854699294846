import React from "react";
// import { Link } from "react-router-dom";
import Logo from "../assets/logo-batitu-color.png";
import LogoDesktop from "../assets/logo-batitu-color-desktop.png";
import history from "../helpers/history"
import DivisorLine from "../assets/linea-Batitu.png";
import CyberMonday from "../assets/logocybermonday-huinchas.png";
import CyberDay from "../assets/logo-cyber-top-home.png";

import Carousel from "./Carousel.jsx"

import { ReactComponent as InstaIcon } from "../assets/svg/instagram.svg";
import { ReactComponent as FaceIcon } from "../assets/svg/facebook.svg";

const Header = () => {
    const handleClick = (e) =>{
        history.push("/")
    }
    return (
        <header className="header">
            <div className="green-header-bar">
            {/* <div className="blue-header-bar">
            <b id="cyberday-timer" className="header-timer">
                <span id="cyberday-timer1"></span>
                <span id="cyberday-timer2"></span>
                <span id="cyberday-timer3"></span>
                <span id="cyberday-timer4"></span>
    </b>*/}
            <b className="header-top-text">¡Descuentos para los primeros pasos!</b>
            {/*<a href="/"><img src={CyberDay} className="header-top"/></a> */}
            </div>
            <div className="columns">
                <div className="column is-12 logo-container">
                    <div className="logo-centered logo-shop">
                    <a href="/"><img src={LogoDesktop} alt="" class="desktop-description"/><img src={Logo} alt="" class="mobil-description"/></a>
                    </div>
                    <div className="banner banner-shop">
                        <div className="social-icon instagram is-pulled-right">
                            <a href="https://www.instagram.com/babybatitu_mx/" target="_blank" rel="noopener noreferrer">
                                <InstaIcon />
                            </a>
                        </div>
                        <div className="social-icon facebook is-pulled-right">
                            <a href="https://www.facebook.com/Batitú-México-107557874752919" target="_blank" rel="noopener noreferrer">
                                <FaceIcon />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column is-12" style={{ paddingTop: "0"}}>
                    <div className="is-centered home-text">
                        <p>
                        ¡Cuidando el planeta desde sus primeros pasos!
                        </p>
                    </div>
                 <div className="green-header-bar-bottom">
                     <img src={DivisorLine}/>
                 </div>
                </div>
            </div>
            <div className="columns">
                <div className="column is-12" style={{ paddingTop: "0"}}>
                    <div className="is-centered home-text">
                        <Carousel/>
                    </div>
                 <div className="green-header-bar-bottom">
                     <img src={DivisorLine}/>
                 </div>
                </div>
            </div>
        </header>
    );
};
export default Header;
